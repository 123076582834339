import React, { useState } from "react";
import { Col, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import css from "./BuyForm.module.css";
import style from "./Product.module.css";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Ім'я має бути не менше 3 символів")
    .max(32, "Ім'я не може бути довшим за 50 символи")
    .matches(
      /^[a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ\s]+$/,
      "Ім'я може містити лише літери та пробіли"
    )
    .required("Введіть ваше ім'я"),

  phone: Yup.string()
    .matches(/^\d{10}$/, "Некоректний номер телефону")
    .required("Введіть ваш номер телефону"),

  address: Yup.string()
    .max(100, "Адреса не може бути довшою за 200 символів")
    .required("Введіть адресу доставки"),

  comment: Yup.string().max(
    500,
    "Коментар не може бути довшим за 500 символів"
  ),
  blocks: Yup.string().max(500, "Коментар не може бути довшим за 500 символів"),
});

const BuyForm = ({ product, orderModal, setOrderResult }) => {
  const [quantity, setQuantity] = useState(1);

  const price = product.salePrice || product.price;

  const handleSubmit = async (values, { resetForm }) => {
    const { title, salePrice, price } = product;

    const message = `
      🛒 Нове замовлення:
      📦 Продукт: ${title}
      💵 Ціна за одиницю: ${salePrice || price} грн.
      🔢 Кількість: ${quantity}
      💰 Загальна сума: ${quantity * (salePrice || price)} грн.
      👤 Ім'я: ${values.name}
      📞 Телефон: ${values.phone}
      📍 Адреса: ${values.address}
      💬 Коментар: ${values.comment || "Немає"}
    `;

    try {
      const response = await fetch(
        "https://bricks-land.com/.netlify/functions/sendMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: "5141047645",
            text: message,
            parse_mode: "HTML",
          }),
        }
      );

      await response.json();

      if (response.ok) {
        setOrderResult(
          "Ваше замовлення успішно зформовано!\nНаш менеджер зв'яжеться з вами найближчім часом"
        );
      } else {
        setOrderResult("Виникла помилка.\nСпробуйте пізніше.");
      }
    } catch (error) {
      setOrderResult("Помилка з'єднання.\nСпробуйте пізніше.");
    } finally {
      setTimeout(() => {
        orderModal(false);
      }, 5000);

      resetForm();

      setTimeout(() => {
        setOrderResult("");
      }, 6000);
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        address: "",
        blocks: "",
        comment: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Col className="ps-1 pe-1">
        <Form className={`pb-1 px-4 ${css.form}`} autoComplete="off">
          <label className={css.label} htmlFor="name">
            Ваше ім'я та прізвище
            <Field type="text" name="name" id="name" />
            <ErrorMessage name="name" component="div" className="text-danger" />
          </label>
          <label className={css.label} htmlFor="phone">
            Введіть ваш номер телефону
            <Field
              type="text"
              name="phone"
              id="phone"
              placeholder="0934057570"
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="text-danger"
            />
          </label>
          <label className={css.label} htmlFor="address">
            Вкажіть адресу доставки
            <Field type="text" name="address" id="address" />
            <ErrorMessage
              name="address"
              component="div"
              className="text-danger"
            />
          </label>
          {product.chooseBlocks && (
            <label htmlFor="blocks">
              {product.chooseBlocks}
              <div className="d-flex align-items-center justify-content-center mb-3" >
                <button
                  type="button"
                  onClick={() => {
                    const newValue = Math.max(1, quantity - 1); 
                    setQuantity(newValue);
                  }}
                  style={{
                    marginRight: "5px",
                    padding: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  -
                </button>
                <Field
                  type="number"
                  name="blocks"
                  id="blocks"
                  min="1"
                  max="1000"
                  value={quantity}
                  onFocus={() => {
                    setQuantity(""); 
                  }}
                  onBlur={() => {
                    if (!quantity || quantity < 1) {
                      setQuantity(1); 
                    }
                  }}
                  onChange={(e) => {
                    const newValue = Math.max(
                      1,
                      Math.min(1000, parseInt(e.target.value) || 1)
                    );
                    setQuantity(newValue);
                  }}
                  style={{
                    width: "60px",
                    textAlign: "center",
                    marginRight: "5px",
                  }}
                />
                <button
                  type="button"
                  onClick={() => {
                    const newValue = Math.min(1000, quantity + 1); // Максимум 1000
                    setQuantity(newValue);
                  }}
                  style={{
                    padding: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  +
                </button>
              </div>
              <ErrorMessage
                name="blocks"
                component="div"
                className="text-danger"
              />
            </label>
          )}

          <Col>
            <p>
              <b>Ваше замовлення:</b>
              <br />"{product.title}" на суму {quantity * price} грн.
            </p>
          </Col>
          <label className={css.label} htmlFor="comment">
            Коментарій до замовлення
            <Field as="textarea" type="text" name="comment" id="comment" />
            <ErrorMessage
              name="comment"
              component="div"
              className="text-danger"
            />
          </label>
          <Col className="d-flex justify-content-evenly mt-4">
            <Button className={style.buyBtn} type="submit">
              Сформувати замовлення
            </Button>
          </Col>
        </Form>
      </Col>
    </Formik>
  );
};

export default BuyForm;
