import { Image, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import styles from "./ScrollToTop.module.css";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Col>
      <button
        onClick={scrollToTop}
        className={`${styles.scrollToTop} ${isVisible ? styles.visible : ""}`}
        aria-label="Scroll to top"
      >
        <Image
          className={styles.image}
          src={"/images/Path.png"}
          alt="up"
          width={30}
          height={20}
        />
      </button>
    </Col>
  );
};

export default ScrollToTop;
