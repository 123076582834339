export const products = [
  {
    id: 1,
    salePrice: false,
    price: 800,
    title: "Портал до раю",
    chooseBlocks: false,
    quantityBlock: "38 блоків",
    quantityHero: "1 Герой",
    images: ["/images/портал2_1.png", "/images/портал2_21.png"]
  },
  {
    id: 2,
    salePrice: false,
    price: 800,
    title: "Портал в нижній світ",
    chooseBlocks: false,
    quantityBlock: "38 блоків",
    quantityHero: "1 Герой",
    images: ["/images/портал1_1.png", "/images/портал1_21.png"]
  },
  {
    id: 3,
    salePrice: false,
    price: 900,
    title: "Алмазний меч",
    chooseBlocks: false,
    quantityBlock: "42 блоки",
    quantityHero: "1 Герой",
    images: ["/images/Ал.меч_1.png", "/images/Ал.меч_21.png"]
  },
  {
    id: 4,
    salePrice: 999,
    price: 1200,
    title: "Санчата Грінча",
    chooseBlocks: false,
    quantityBlock: "49 блоків",
    quantityHero: "1 Герой (Грінч)",
    images: ["/images/Грінч_1.png", "/images/Грінч_21.png"]
  },
  {
    id: 5,
    salePrice: 3850,
    price: 4200,
    title: "5 Локацій",
    chooseBlocks: false,
    quantityBlock: "197 блоків",
    quantityHero: "3 Героя",
    images: ["/images/5локацій_1.png", "/images/5локацій_2.png", "/images/5локацій_3.png",]
  },
  {
    id: 6,
    salePrice: false,
    price: 1900,
    title: "Водоспад",
    chooseBlocks: false,
    quantityBlock: "93 блока",
    quantityHero: "2 Героя",
    images: ["/images/поляна_1.png", "/images/поляна_2.png"]
  },
  {
    id: 7,
    salePrice: false,
    price: 3550,
    title: "Кам'яна вежа",
    chooseBlocks: false,
    quantityBlock: "173 блока",
    quantityHero: "3 Героя",
    images: ["/images/вежа-1.png", "/images/вежа-3.png", "/images/вежа-2.png"]
  },
  {
    id: 8,
    salePrice: 20,
    price: 25,
    title: "Блоки",
    chooseBlocks: "Оберіть кількість блоків:",
    quantityBlock: "1 блок на вибір",
    images: ["/images/Блоки_1.png", "/images/Блоки_2.png"]
  }
]
