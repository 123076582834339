import React from "react";
import { Col, Row } from "react-bootstrap";
import css from "./Footer.module.css";
import { TbTruckDelivery } from "react-icons/tb";
import { MdPhoneInTalk } from "react-icons/md";
import { MdOutlineShoppingCart } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaViber } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
//import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  const dateYear = new Date().getFullYear();
  const email = "bricks-land@ukr.net";
  const phone = "+38(068)-883-84-93";

  return (
    <Col xs={12} className={css.bgFooter}>
      <Row>
        <Col xs={12} md={6}>
          <div className="d-flex align-items-center mb-2 mb-md-3 justify-content-center justify-content-md-start">
            <TbTruckDelivery className={css.icons} />
            <p className={css.text}>Доставка по всій Україні</p>
          </div>
          <div className="d-flex align-items-center mb-2 mb-md-3  justify-content-center justify-content-md-start">
            <MdPhoneInTalk className={css.icons} />
            <p className={css.text}>Консультація та підтримка</p>
          </div>
          <div className="d-flex align-items-center mb-2 mb-md-3  justify-content-center justify-content-md-start">
            <MdOutlineShoppingCart className={css.icons} />
            <p className={css.text}>Захист прав споживача</p>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="fs-3 text-center text-md-start mb-2">
            <b>Контакти:</b>
          </div>
          <div className="d-flex align-items-center justify-content-center justify-content-md-start">
            <FaPhoneAlt size={25} />
            <button
              type="button"
              className={`${css.contacts} ${css.text} "fs-5 text-center text-md-start"`}
              style={{ cursor: "pointer" }}
            >
              {phone}
            </button>
          </div>
          <div className="d-flex align-items-center mt-3 mb-3 justify-content-center justify-content-md-start">
            <div>
              <MdOutlineMail size={30} />
            </div>
            <button type="button" className={`${css.text} ${css.contacts}`}>
              {email}
            </button>
          </div>
          <div className="mt-4">
            <div className="d-flex justify-content-center justify-content-md-start">
              <a href="viber://chat?number=+380688838493" target="blank">
                <div className={css.iconContactsBox}>
                  <FaViber
                    size={40}
                    className={css.iconContacts}
                    style={{ color: "#6f44a8" }}
                  />
                </div>
              </a>
              <a href="https://t.me/MagneticMinecraftWorld" target="blank">
                <div className={css.iconContactsBox}>
                  <FaTelegramPlane size={40} className={css.iconContacts} style={{ color: "#0088CC" }}/>
                </div>
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <div className="mt-5" style={{ fontSize: "14px" }}>
        Інтернет-магазин "Bricks land"&copy; {dateYear}
      </div>
    </Col>
  );
};

export default Footer;
