import React from "react";
import { Col, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import css from "../Products/BuyForm.module.css";
import style from "../Products/Product.module.css";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Ім'я має бути не менше 3 символів")
    .max(32, "Ім'я не може бути довшим за 50 символи")
    .matches(
      /^[a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ\s]+$/,
      "Ім'я може містити лише літери та пробіли"
    ),

  phone: Yup.string()
    .matches(/^\d{10}$/, "Некоректний номер телефону")
    .required("Введіть ваш номер телефону"),
});

const CallingForm = ({ setCallingModal, setCallingResult, setHideCall }) => {
  const handleSubmit = async (values, { resetForm }) => {
    const message = `
       Замовлений дзвінок:!
      👤 Ім'я: ${values.name || "Покупець"}
      📞 Телефон: ${values.phone}
    `;

    try {
      const response = await fetch(
        "https://bricks-land.com/.netlify/functions/sendMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: "5141047645",
            text: message,
            parse_mode: "HTML",
          }),
        }
      );

      await response.json();

      if (response.ok) {
        setCallingResult(
          "Очікуйте дзвінка!\nНаш менеджер зв'яжеться з вами найближчім часом."
        );
      } else {
        setCallingResult("Виникла помилка.\nСпробуйте пізніше.");
      }
    } catch (error) {
      setCallingResult("Помилка з'єднання.\nСпробуйте пізніше.");
    } finally {
      setTimeout(() => {
        setCallingModal(false);
      }, 5000);

      resetForm();
      setHideCall(false);

      setTimeout(() => {
        setCallingResult("");
      }, 6000);
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Col className="ps-1 pe-1">
        <Form className={`pb-1 px-4 ${css.form}`} autoComplete="off">
          <label className={css.label} htmlFor="name">
            Ваше ім'я
            <Field type="text" name="name" id="name" />
            <ErrorMessage name="name" component="div" className="text-danger" />
          </label>
          <label className={css.label} htmlFor="phone">
            Ваш номер телефону
            <Field
              type="text"
              name="phone"
              id="phone"
              placeholder="0934057570"
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="text-danger"
            />
          </label>
          <Col className="d-flex justify-content-evenly mt-4">
            <Button className={style.buyBtn} type="submit">
              Замовити дзвінок
            </Button>
          </Col>
        </Form>
      </Col>
    </Formik>
  );
};

export default CallingForm;
