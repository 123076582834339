import React, { useState, useEffect } from "react";
import { Col, Image } from "react-bootstrap";
import css from "./GetColling.module.css";
import { MdPhoneCallback } from "react-icons/md";
import CallingForm from "./CallingForm";
import Modal from "../Modal/Modal";

const GetColling = () => {
  const [hideCall, setHideCall] = useState(true);
  const [callingModal, setCallingModal] = useState(false);
  const [callingResult, setCallingResult] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHideCall(false);
    }, 7000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Col className={`${css.boxCall} ${hideCall ? "d-none" : ""}`}>
      <Image
        src={"/images/closeBtn.svg"}
        alt="close"
        width={30}
        height={20}
        className={css.closeBtn}
        onClick={() => setHideCall(true)}
      />
      <Col className={css.calling} onClick={() => setCallingModal(true)}>
        <MdPhoneCallback className={css.iconAnimation} />
        <p className="m-0 p-0">
          Замовити
          <br />
          дзвінок
        </p>
      </Col>

      {callingModal && (
        <Modal
          show={callingModal}
          onClose={() => setCallingModal(false)}
          style={{
            height: "auto",
            width: "95%",
            maxWidth: "400px",
            background: "linear-gradient(to bottom, #2dbdb8, #fff)",
          }}
        >
          {callingResult.length !== 0 ? (
            <b className="py-5 px-2 fs-4">{callingResult}</b>
          ) : (
            <CallingForm
              setCallingModal={setCallingModal}
              setCallingResult={setCallingResult}
              setHideCall={setHideCall}
            />
          )}
        </Modal>
      )}
    </Col>
  );
};

export default GetColling;
