export const reviews = [
  {
    id: 1,
    name: "Olha",
    mark: "★★★★★",
    coment: "Задоволені на всі 100%!",
    buyProduct: "Алмазний меч",
    date: "11.11.2024"
  },
  {
    id: 2,
    name: "Ірина",
    mark: "★★★★★",
    coment: "Син в захваті. Кубики якісні, гарно магнітяться один до одного, піксельний малюнок був приємним доповненням",
    buyProduct: "Портал в нижній світ",
    date: "16.11.2024"
  },
  {
    id: 3,
    name: "Сергій Ковальчук",
    mark: "★★★★★",
    coment: "Замовляли окремо вибрані блоки 200шт та песонажів, дуже зручно що можна вибрати конкретні блоки. ",
    buyProduct: "Блоки",
    date: "24.11.2024"
  },
  {
    id: 4,
    name: "Анастасія Чернова",
    mark: "★★★★★",
    coment: "Конструктор супер! Яскраві кубики, магніти сильні, замовлю іще набір:) ",
    buyProduct: "Портал в нижній світ",
    date: "27.11.2024"
  },
  {
    id: 5,
    name: "Катя Бойко",
    mark: "★★★★",
    coment: "Купувала на подарунок! Також порадувала оперативна відправка в день замовлення",
    buyProduct: "Портал до раю",
    date: "02.12.2024"
  }
]